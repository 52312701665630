import React, { useEffect, useState} from "react";
import {
  Button,
  Divider,
  Form,
  message,
  Rate,
  Table,
  Tag,
} from "antd";
import { Navigate, useNavigate } from "react-router";
import {
  DateField,
  SearchField,
  Section,
  SelectField,
  TextField,
} from "components";
import {formatCurrency, formatDate} from "utils/helpers";
import {
  useBusinessUnits,
  useProduct,
  useSuppliers,
} from "repositories";
import { useAuth, usePagination } from "hooks";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import { DefaultOptionType } from "antd/lib/select";
import moment from "moment";
import {
  supplierStatus,
  SupplierStatus,
  SupplierType,
} from "../../../../utils/constants";

const timingOptions = [
  { name: "Planejada", id: 1 },
  { name: "Não planejada", id: 2 },
];
const providerTypeOptions = [
  { name: "Freelancer", id: 1 },
  { name: "Fornecedor", id: 2 },
];


export type TOptions = {
  id: number | string;
  name: string;
};

const convertToOptions = (items: TOptions[]) =>
  items.map(
    (item) =>
      ({
        label: item.name,
        value: item.id,
      } as DefaultOptionType)
  );

const totalTimeCalculated = (initialDate: moment.Moment, finalDate: moment.Moment) => {

  return finalDate.diff(initialDate, 'days') + 1;

}

const FormProvider = ({
  productSelected,
  supplierSelected,
  selectDataForm,
  BUSelected,
  handleProviderType,
  nextStep,
  prevStep
}: any) => {

  function applyPhoneMaskWithNinthDigit(phoneNumber: any) {
    const onlyNumbers = phoneNumber.replace(/\D/g, ""); // Remove caracteres não numéricos

    let formattedNumber;
    if (onlyNumbers.length === 11) {
      formattedNumber = onlyNumbers.replace(
          /(\d{2})(\d{5})(\d{4})/,
          "($1) $2-$3"
      );
    } else if (onlyNumbers.length === 10) {
      formattedNumber = onlyNumbers.replace(
          /(\d{2})(\d{4,5})(\d{4})/,
          "($1) $2-$3"
      );
    } else {
      return phoneNumber;
    }

    return formattedNumber;
  }

  const columnsSuppliers: any = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Razão Social",
      dataIndex: "corporateName",
    },
    {
      title: "Nome fantasia ",
      dataIndex: "fantasyName",
    },
    {
      title: "Tipo de Prestador",
      dataIndex: "typeSupplier",
      render: (_: any, record: any) =>
          record.typeSupplier === SupplierType.SUPPLIER
              ? "Fornecedor"
              : "Freelancer",
    },
    {
      title: "Telefone",
      dataIndex: "telephone",
      render: (_: any, record: any) => {
        return applyPhoneMaskWithNinthDigit(record.telephone);
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Status Homologação",
      dataIndex: "status",
      render: (_: any, record: any) => {
        if (record.status === SupplierStatus.APPROVED) {
          return <Tag color="green">Homologado</Tag>;
        }

        if (record.status === SupplierStatus.NOT_APPROVED) {
          return <Tag color="red">Bloqueado</Tag>;
        }

        if (record.status === SupplierStatus.PRE_REGISTRATION) {
          return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
        }

        return <Tag>{supplierStatus[record.status]}</Tag>;
      },
    },
    {
      title: "Preciario",
      dataIndex: "preciary",
      align: "center",
      render: (_: any, record: any) => {
        if (record.preciary) {
          return <Tag>Sim</Tag>;
        } else {
          return <Tag>Não</Tag>;
        }
      },
    },
    {
      title: "Valor negociado",
      dataIndex: "value",
      align: "center",
      render: (_: any, record: any) => (
          <span>{formatCurrency(record.value)}</span>
      ),
    },
    {
      title: "Unidade de medida",
      dataIndex: "unitOfMeasurement",
      align: "center",
    },
    {
      title: "Avaliações",
      dataIndex: "currentAmountEvaluation",
      align: "center",
      render: (_: any, record: any) => (
          <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
            <Rate disabled value={record.currentEvaluation}/>
            <span>{`(${record.currentAmountEvaluation})`}</span>
          </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => (
          <Button onClick={() => setSuppliers([])}>Excluir</Button>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [suppliersSearch, setSuppliersSearch] = useState<any>([]);
  const [productOptions, setProductOptions] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [valueProduct, setValueProduct] = useState<any>([]);
  const [productChosen, setProductChosen] = useState<any>({});
  const [bus, setBUs] = useState([]);
  const [optionsBU, setBUOptions] = useState([]);
  const [bu, setBu] = useState<any>();
  const [optionsSearchSuppliers, setOptionsSearchSuppliers] = useState<any>([]);
  const [mappedSuppliers, setMappedSuppliers] = useState<any>([]);
  const [suppliersId, setSuppliersId] = useState<any>("");
  const timingOptionsConvert = convertToOptions(timingOptions);

  const providerTypeOptionsConvert = convertToOptions(providerTypeOptions);

  const requestsColumns: any = [
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
            ? moment(record.issueDate).format("DD/MM/YYYY")
            : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
            ? moment(record.maturity).format("DD/MM/YYYY")
            : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "client",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.totalCost);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
  ];

  const SenioridadeOptionsConvert = [
    {
      label: "Júnior",
      value: "JUNIOR",
    },
    {
      label: "Pleno",
      value: "PLENO",
    },
    {
      label: "Sênior",
      value: "SENIOR",
    },
  ];

  const {roles: authRoles, hasRole} = useAuth();

  const repositoryProduct = useProduct();
  const repositorySupplier = useSuppliers();
  const buRepository = useBusinessUnits();

  const pagination = usePagination();

  const requestForm = async () => {
    const response = await buRepository.findBus({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.name }));
    setBUOptions(mapped);
    setBUs(response.content);

  };

  const onChangeStartDate = (date: moment.Moment | null) => {
    const startDate = moment(date);
    const endDate = moment(form.getFieldValue("endDate"));
    initialValues.startDate = startDate;
    if (endDate.isBefore(startDate)) {
      form.setFieldValue("endDate", startDate);
    }
    form.setFieldValue("TotalTime", totalTimeCalculated(form.getFieldValue("startDate"), form.getFieldValue("endDate")));
  };

  const onChangeEndDate = (date: moment.Moment | null) => {
    if (date === null) return moment()
    const endDate = moment(date);
    form.setFieldValue("endDate", endDate);
    form.setFieldValue("TotalTime", totalTimeCalculated(form.getFieldValue("startDate"), form.getFieldValue("endDate")));
  };

  const onChangetiming = (data: number) => {
    if (data === 1) {
      form.setFieldsValue({
        pay: form.getFieldValue("endDate")?.clone()?.add(30, "days"),
      });
    }
    const emissao = moment(form.getFieldValue("emission"));
    const start = moment(form.getFieldValue("startDate"));
    if (data === 1 && start.isBefore(emissao)) {
      form.setFieldValue("startDate", emissao);
    }
  };

  const onChangeProvider = (data: any) => {
    setSuppliers([]);
    form.setFieldValue("seniority", "")
    form.setFieldValue("unitMinimunPrice", "")
    form.setFieldValue("unitMaximunPrice", "")
    handleProviderType(data);
  };

  let initialValues = {
    typeRequest: "Composta",
    category: "Rateios e contratos",
    allocation: "Com projeto",
    status: "Nova",
    timing: 1,
    productSelection: '',
    businessUnits: '',
    providerType: '',
    seniority: '',
    emission: moment(),
    startDate: moment(),
    endDate: moment(),
    TotalTime: "",
    ExpenseOrInvestment: "",
    unitMinimunPrice: "",
    unitMaximunPrice: "",
  };

  useEffect(() => {
    if (pagination.data.current) requestForm();
  }, [
    pagination.data.current,
    pagination.data.pageSize,
  ]);

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado"/>;
  }

  const onFinish = async (values: any) => {
    const data = {
      typeRequest: "Composta",
      category: "Rateios e contratos",
      allocation: "Com projeto",
      status: "Nova",
      timing: values.timing,
      productSelection: values.productSelection,
      businessUnits: values.businessUnits,
      providerType: values.providerType,
      seniority: values.seniority,
      emission: formatDate(values.emission),
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      totalTime: values.totalTime,
      ExpenseOrInvestment: "", // ajustar
      unitMinimunPrice: values.unitMinimunPrice,
      unitMaximunPrice: values.unitMaximunPrice,
    };

    if (suppliers.length !== 0 && productChosen.length !== 0) {

      selectDataForm(data);
      productSelected(productChosen)
      supplierSelected(suppliers[0])
      BUSelected(bu)
      nextStep();

    } else {

      selectDataForm();
      productSelected()
      supplierSelected()
      BUSelected()

      message.error("Preencha todos os campos corretamente");
    }
  };

  const prev = (e: any) => {
    prevStep();
  };
  const next = (e: any) => {
    const values = form.getFieldsValue();
    onFinish(values);
  };

  const onChangeSuppliers = (data: string) => {
    const indexOf = suppliersSearch.findIndex(
        (obj: { corporateName: string }) => obj.corporateName === data
    );

    const mappedSuppliers = suppliersSearch.map((request: any) => ({
      id: String(request.id),
      currentEvaluation:
          request.currentEvaluation !== null ? request.currentEvaluation : 0,
      currentAmountEvaluation: request.currentAmountEvaluation
          ? request.currentAmountEvaluation
          : 0,
      status: request.status,
      typeSupplier: request.typeSupplier,
      telephone: request.telephone,
      email: request.email,
      fantasyName: request.fantasyName,
      corporateName: request.corporateName,
    }));
    setMappedSuppliers([mappedSuppliers[indexOf]]);
    setSuppliersId(mappedSuppliers[indexOf].id);
  };

  const onChangeSenioridade = async (data: any) => {
    const params: any = {
      id: productChosen?.id,
    };

    const r = await repositoryProduct.findProductByFilter(params);
    let seniorityData = {} as any;
    switch (data) {
      case "JUNIOR":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
              Number(r?.content[0]?.minimumPriceJunior)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
              Number(r?.content[0]?.maximumPriceJunior)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
            r?.content[0]?.minimumPriceJunior
        );
        seniorityData.unitMaximunPrice = Number(
            r?.content[0]?.maximumPriceJunior
        );
        break;
      case "PLENO":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
              Number(r?.content[0]?.minimumPricePleno)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
              Number(r?.content[0]?.maximumPricePleno)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
            r?.content[0]?.minimumPricePleno
        );
        seniorityData.unitMaximunPrice = Number(
            r?.content[0]?.maximumPricePleno
        );
        break;
      case "SENIOR":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
              Number(r?.content[0]?.minimumPriceSenior)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
              Number(r?.content[0]?.maximumPriceSenior)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
            r?.content[0]?.minimumPriceSenior
        );
        seniorityData.unitMaximunPrice = Number(
            r?.content[0]?.maximumPriceSenior
        );
        break;
    }
    setSuppliers([]);
    setSuppliersId('');
    setOptionsSearchSuppliers([]);
    form.setFieldValue("SearchFieldSuppliers","")
  };

  const disabledDate = (current: any) => {
    const today = new Date(form.getFieldValue("emission"));
    today.setHours(0, 0, 0, 0);
    return current && current < today;
  };

  const onSearchProduct = async (searchText: any) => {
    const response = await repositoryProduct.findProductByFilter({
      description: searchText,
      page: 0,
      size: 10000,
      activityDirector: true,
    })
    if (!response) return

    const mappedUsers = response.content.map(item => ({ id: item.id, value: item.description }))
    setProductOptions(mappedUsers)
    setProducts(response.content)
  };

  const onChangeProduct = (data: any) => {
    const findProduct = products.find((product: { description: string }) => product.description === data)
    if (!findProduct) return

    setValueProduct(data)
    setProductChosen(findProduct)
    form.setFieldValue("productId",findProduct.id)

    setSuppliers([]);
    setSuppliersId("");
    setOptionsSearchSuppliers([]);
    form.setFieldValue('SearchFieldSuppliers', '')
    form.setFieldValue('seniority', '')
    form.setFieldValue('unitMinimunPrice', '')
    form.setFieldValue('unitMaximunPrice', '')
    form.setFieldValue('providerType', '')
  };

  const onSearchSuppliers = async (searchText: any) => {
    const providerType = form.getFieldValue("providerType");
    const seniorityform = form.getFieldValue("seniority");
    const productId = form.getFieldValue("productId");

    const queryParams = new URLSearchParams({
      corporateName: searchText,
      typeSupplier: providerType === 1 ? "FREELANCE" : "SUPPLIER",
      requestTiming: form.getFieldValue("timing") === 1 ? "true" : "false",
      page: "0",
      size: "10000",
    });
    queryParams.append("productIds", productId);
    queryParams.append("seniority", seniorityform ? seniorityform : "");
    const response = await repositorySupplier.findSupplierByFilter(queryParams);
    if (!response) return;

    const mappedSuppliers = response.content.map((item) => ({
      value: item.corporateName,
      id: item.id,
    }));
    setOptionsSearchSuppliers(mappedSuppliers);
    setSuppliersSearch(response.content);
  }


  const onAdd = async () => {
    const indexOf = mappedSuppliers.findIndex(
        (obj: { id: string }) => String(obj.id) === String(suppliersId)
    );
    const r = await repositorySupplier.getProductPriceRange(
        mappedSuppliers[indexOf]?.id
    );
    if (r === undefined){
      message.error("Selecione corretamente o fornecedor ou freelancer para essa requisição")
      return
    }
    const filtered = r.filter(
        (product: any) => product.productId === productChosen.id
    );

    let mapped = mappedSuppliers[indexOf];
    mapped.preciary = filtered[0]?.preciary;
    mapped.value = filtered[0]?.value ? filtered[0]?.value : "";
    mapped.unitOfMeasurement = filtered[0]?.unitOfMeasurement
        ? filtered[0]?.unitOfMeasurement
        : "";
    setSuppliers([mapped]);
    setOptionsSearchSuppliers([]);
    form.setFieldValue("SearchFieldSuppliers", "");
  };

  const onChangeBU = (newValue: any) => {
    const _bu = bus
        .map((bu: any) => ({ id: bu.id, name: bu.name }))
        .filter((bu: any) => bu.name === newValue);
    setBu(_bu[0]);

    setSuppliers([]);
    setSuppliersId("");
    setOptionsSearchSuppliers([]);
    form.setFieldValue('SearchFieldSuppliers', '')
    form.setFieldValue('seniority', '')
    form.setFieldValue('unitMinimunPrice', '')
    form.setFieldValue('unitMaximunPrice', '')
    form.setFieldValue('providerType', '')
  };

  const disabledSearchField = (provider: any, senioridade: any, productSelection: any, businessUnits: any) => {
    if (businessUnits.length > 0 && productSelection.length > 0){
      if (provider === 2) {
        return false;
      } else if (provider === 1 && senioridade?.length > 0) {
        return false;
      }
    }

    return true;
  };

  return (
      <>
        <Section>
          <div style={{marginTop: 24}}>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={initialValues}
            >
              <>
                <Row>
                  <Col xl={24}>
                    <b>Categorização, datas e gatilhos 4</b>
                  </Col>
                  <Col xl={6} xxl={3}>
                    <TextField
                        name="typeRequest"
                        label="Tipo da requisição"
                        disabled
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <TextField
                        name="category"
                        label="Categoria da requisição"
                        disabled
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <TextField
                        name="allocation"
                        label="Alocação da requisição"
                        disabled
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <TextField name="status" label="Status" disabled/>
                  </Col>
                  <Col xl={6} xxl={3}>
                    <SelectField
                        name="timing"
                        label="Timing da requisição"
                        onChange={onChangetiming}
                        options={timingOptionsConvert}
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <SearchField
                        name="productSelection"
                        label="Escolha o produto"
                        options={productOptions}
                        onChange={onChangeProduct}
                        onSearch={onSearchProduct}
                        value={valueProduct}
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <SelectField
                        name="businessUnits"
                        label="Escolha a BU"
                        options={optionsBU}
                        onChange={onChangeBU}
                        value={bu}
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <SelectField
                        name="providerType"
                        label="Tipo de Prestador"
                        options={providerTypeOptionsConvert}
                        onChange={onChangeProvider}
                    />
                  </Col>
                  <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                          prevValues.providerType !== currentValues.providerType
                      }
                  >
                    {({getFieldValue}) => (
                        <Col xl={6} xxl={3}>
                          <SelectField
                              name="seniority"
                              label="Senioridade"
                              options={SenioridadeOptionsConvert}
                              onChange={onChangeSenioridade}
                              disabled={
                                getFieldValue("providerType") === 1 ? false : true
                              }
                          />
                        </Col>
                    )}
                  </Form.Item>

                  <Col xl={6} xxl={3}>
                    <DateField name="emission" label="Emissão" disabled/>
                  </Col>
                  <Col xl={6} xxl={3}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.timing !== currentValues.timing
                        }
                    >
                      {({getFieldValue}) => (
                          <DateField
                              name="startDate"
                              label="Data do início do serviço"
                              disabledDate={
                                getFieldValue("timing") === 1
                                    ? disabledDate
                                    : () => {
                                    }
                              }
                              onChange={onChangeStartDate}
                          />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={6} xxl={3}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.timing !== currentValues.timing
                        }
                    >
                      {({getFieldValue}) => (
                        <DateField
                            name="endDate"
                            label="Data do Termino do serviço"
                            onChange={onChangeEndDate}
                            disabledDate={
                              getFieldValue("timing") === 1
                                  ? disabledDate
                                  : () => {
                                  }
                            }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={6} xxl={3}>
                    <TextField
                        name="TotalTime"
                        label="Tempo total do serviço"
                        disabled={true}
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <TextField
                        name="ExpenseOrInvestment"
                        label="CAPEX/OPEX ?"
                        placeholder={"Despesa"}
                        disabled
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <TextField
                        name="unitMinimunPrice"
                        label="Preço mínimo [Freela]"
                        disabled
                    />
                  </Col>
                  <Col xl={6} xxl={3}>
                    <TextField
                        name="unitMaximunPrice"
                        label="Preço Máximo [Freela]"
                        disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Divider/>
                  </Col>
                  <Col xl={24}>
                    <b>Fornecedores ou freelancers para essa requisição</b>
                  </Col>
                  <Col xl={12} xxl={12}>
                    <div style={{display: "flex", width: "100%"}}>
                      <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                              prevValues.providerType !==
                              currentValues.providerType ||
                              prevValues.senioridade !== currentValues.senioridade
                          }
                      >
                        {({getFieldValue}) => (
                            <SearchField
                                onSearch={onSearchSuppliers}
                                onChange={onChangeSuppliers}
                                name="SearchFieldSuppliers"
                                placeholder="Digite aqui o nome"
                                options={optionsSearchSuppliers}
                                disabled={disabledSearchField(
                                    getFieldValue("providerType"),
                                    getFieldValue("seniority"),
                                    getFieldValue("productSelection"),
                                    getFieldValue("businessUnits")
                                )}
                                value={""}
                            />
                        )}
                      </Form.Item>
                      <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                              prevValues.providerType !==
                              currentValues.providerType ||
                              prevValues.senioridade !== currentValues.senioridade
                          }
                      >
                        {({getFieldValue}) => (
                            <Button
                                type="primary"
                                onClick={onAdd}
                                disabled={disabledSearchField(
                                    getFieldValue("providerType"),
                                    getFieldValue("seniority"),
                                    getFieldValue("productSelection"),
                                    getFieldValue("businessUnits")
                                )}
                            >
                              Adicionar
                            </Button>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col>
                    <Table
                        columns={
                            columnsSuppliers
                        }
                        dataSource={suppliers}
                        pagination={false}
                    />
                  </Col>
                  <Col>
                    <div style={{marginBottom: "40px"}}></div>
                  </Col>
                </Row>
                <Button style={{margin: "0 4px"}} onClick={prev} disabled  >
                  Voltar
                </Button>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.providerType !== currentValues.providerType
                    }
                >
                  <Button
                      style={{margin: "0 4px"}}
                      type="primary"
                      htmlType="submit"
                      onClick={next}
                  >
                    Próximo
                  </Button>
                </Form.Item>
              </>
            </Form>
          </div>
        </Section>
      </>
  );
};

export default FormProvider;
